import React from "react"

import {
  RowSectionElement,
  RowSectionBackgroundImageElement,
  RowOverlayColorElement,
  TwoColumnSectionElement,
  TwoColumnSectionLeftElement,
  TwoColumnSectionRightElement,
  OneColumnSectionElement,
  TwoColumnSectionLeftTopElement,
  OverlapRowElement,
  ThreeColumnElement,
  ThreeColumnOneElement,
  ThreeColumnTwoElement,
  ThreeColumnThreeElement,
  ColumnInternalElement,
  ColumnInternalTopElement,
  ColumnInternalBottomElement,
  DivHideOnMobileElement,
  ColumnWideMobileReducedDesktopElement,
  RowDesktopColumnMobileElement,
} from "../elements/SectionBlocksElements"

const RowSectionBackground = (props) => {
  if (props.backgroundUrl) {
    return (
      <RowSectionBackgroundImageElement fluid={props.backgroundUrl} bgX={props.bgX} bgY={props.bgY}>
        {props.children}
      </RowSectionBackgroundImageElement>
    )
  } else {
    return props.children
  }
}

const RowSectionOverlay = (props) => {
  if (props.overlay) {
    return (
      <RowOverlayColorElement overlay={props.overlay} opacity={props.opacity}>
        {props.children}
      </RowOverlayColorElement>
    )
  } else {
    return props.children
  }
}

export const RowSection = (props) => {
  return (
    <RowSectionElement
      id={props.name}
      marginLeft={props.rowMargin ? props.rowMargin : "0px"}
      marginRight={props.rowMargin ? props.rowMargin : "0px"}
      {...props}>
      <RowSectionBackground backgroundUrl={props.backgroundUrl} bgX={props.bgX} bgY={props.bgY}>
        <RowSectionOverlay overlay={props.overlay} opacity={props.opacity}>
          {props.children}
        </RowSectionOverlay>
      </RowSectionBackground>
    </RowSectionElement>
  )
}

export const OneColumnSection = (props) => {
  return (
    <OneColumnSectionElement
      className="mx-auto"
      columnLeftWidth={props.columnLeftWidth}
      columnRightWidth={props.columnRightWidth}
      marginLeft={props.sideMargin}
      marginRight={props.sideMargin}
      {...props}>
      {props.children}
    </OneColumnSectionElement>
  )
}

// export const TwoColumnSection = (props) => {
//   return (
//     <TwoColumnSectionElement
//       columnLeftWidth={props.columnLeftWidth}
//       columnRightWidth={props.columnRightWidth}
//       marginLeft={props.sideMargin}
//       marginRight={props.sideMargin}>
//       {props.children}
//     </TwoColumnSectionElement>
//   )
// }

export const DivHideOnMobile = (props) => {
  return <DivHideOnMobileElement {...props}>{props.children}</DivHideOnMobileElement>
}

export const TwoColumnSection = (props) => {
  if (props.columnLeftTop === "true") {
    return (
      <TwoColumnSectionLeftTopElement
        columnLeftWidth={props.columnLeftWidth}
        columnRightWidth={props.columnRightWidth}
        marginLeft={props.sideMargin}
        marginRight={props.sideMargin}
        style={props.style}
        {...props}
        // topColumn={props.columnTop ? props.columnTop : "columnRight"}
        // bottomColumn={props.columnBottom ? props.columnBottom : "columnLeft"}
      >
        {props.children}
      </TwoColumnSectionLeftTopElement>
    )
  } else {
    return (
      <TwoColumnSectionElement
        columnLeftWidth={props.columnLeftWidth}
        columnRightWidth={props.columnRightWidth}
        marginLeft={props.sideMargin}
        marginRight={props.sideMargin}
        style={props.style}
        // topColumn={props.columnTop ? props.columnTop : "columnRight"}
        // bottomColumn={props.columnBottom ? props.columnBottom : "columnLeft"}
      >
        {props.children}
      </TwoColumnSectionElement>
    )
  }
}

export const TwoColumnSectionRight = (props) => {
  return (
    <TwoColumnSectionRightElement
      alignItems={props.alignItems}
      justifyContent={props.justifyContent}
      {...props}>
      {props.children}
    </TwoColumnSectionRightElement>
  )
}

export const TwoColumnSectionLeft = (props) => {
  return (
    <TwoColumnSectionLeftElement
      alignItems={props.alignItems}
      justifyContent={props.justifyContent}
      {...props}>
      {props.children}
    </TwoColumnSectionLeftElement>
  )
}

export const OverlapRow = (props) => {
  return <OverlapRowElement {...props}>{props.children}</OverlapRowElement>
}

export const ThreeColumn = (props) => {
  return <ThreeColumnElement {...props}>{props.children}</ThreeColumnElement>
}

export const ThreeColumnOne = (props) => {
  return <ThreeColumnOneElement {...props}>{props.children}</ThreeColumnOneElement>
}

export const ThreeColumnTwo = (props) => {
  return <ThreeColumnTwoElement {...props}>{props.children}</ThreeColumnTwoElement>
}

export const ThreeColumnThree = (props) => {
  return <ThreeColumnThreeElement {...props}>{props.children}</ThreeColumnThreeElement>
}

export const ColumnInternal = (props) => {
  return <ColumnInternalElement {...props}>{props.children}</ColumnInternalElement>
}

export const ColumnInternalTop = (props) => {
  return <ColumnInternalTopElement {...props}>{props.children}</ColumnInternalTopElement>
}

export const ColumnInternalBottom = (props) => {
  return <ColumnInternalBottomElement {...props}>{props.children}</ColumnInternalBottomElement>
}

export const ColumnWideMobileReducedDesktop = (props) => {
  return (
    <ColumnWideMobileReducedDesktopElement {...props}>
      {props.children}
    </ColumnWideMobileReducedDesktopElement>
  )
}

export const RowDesktopColumnMobile = (props) => {
  return <RowDesktopColumnMobileElement {...props}>{props.children}</RowDesktopColumnMobileElement>
}
